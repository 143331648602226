import { Navigate } from "react-router-dom";
import { useLocalStorage } from "../../shared/utils/useLocalStorage";
import { APP_ROUTING_PATHS, tokenLocalStorageKey, userInfoLocalStorageKey } from "../constants";
import { useAppSelector } from "../store";
import { IUser } from "../auth/auth.interfaces";
import { handleLogOut } from "../../shared/utils/logOut";
import { formatUrlWithParams } from "../../shared/utils/routingUtils";

export const Redirect = () => {
  const [userInfo,] = useLocalStorage<IUser | null>(userInfoLocalStorageKey, null);
  const hasAllNeededKeys = userInfo && userInfo.hasOwnProperty('isActive') && userInfo.hasOwnProperty('canRegister');
  const { loginRes, registrationRes, updatePhoneNumberRes, verifyOtpRes } = useAppSelector(store => store.authReducer);
  const { userAppData } = useAppSelector(store => store.userReducer);
  const [tokenLocalStorage,] = useLocalStorage(tokenLocalStorageKey, '');
  
  if (userInfo && !hasAllNeededKeys) handleLogOut(); 

  const redirect = ():JSX.Element => {
    const user = verifyOtpRes.data || updatePhoneNumberRes.data || registrationRes.data || userAppData.data?.user || userInfo;
    const token = loginRes.data?.token || tokenLocalStorage;

      switch (true) {
        case !token:
          return <Navigate to={formatUrlWithParams(APP_ROUTING_PATHS.SIGN_IN)} />
        case (!user || (user && user?.canRegister && !user.isActive)):
          return <Navigate to={formatUrlWithParams(APP_ROUTING_PATHS.REGISTER)} />;
        case user && !user?.canRegister:
          return <Navigate to={formatUrlWithParams(APP_ROUTING_PATHS.WAIT_LIST)} />;
        case user && !user?.phoneNumber:
          return <Navigate to={formatUrlWithParams(APP_ROUTING_PATHS.ADD_PHONE_REGISTER)} />;
        case user && !!user?.phoneNumber:
          return <Navigate to={`${formatUrlWithParams(`${APP_ROUTING_PATHS.HOME}/${APP_ROUTING_PATHS.CHAT}`)}`} />;
        default:
          return <Navigate to={formatUrlWithParams(APP_ROUTING_PATHS.PAGE_404)} />
      }
  }

  return redirect();
}