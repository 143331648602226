import { MutableRefObject } from "react";
import { EChatMessageDataType, IChatMessage, IChatMessageData, ICreateSessionResponse, IListMessageDataRes, IMessageResponse, TChatMessageType } from "../chat.interfaces";
import { orderTasksListByGroups } from "./chat-conversation/message-data-card/tasks-list/TasksList.utils";
import { IUser } from "../../../app/auth/auth.interfaces";
import { setItemInSessionOrLocalStorage } from "../../../shared/utils/getItemFromSessionOrLocalStorage";
import { didUserCreateOrEditTaskStorageKey, endAddTaskToolName, endUpdateTaskToolName, messagesAmountToDisplayFeedbackModal, messagesCounterStorageKey } from "../../../app/constants";
import { ApplicationInsightsApi } from "../../../application-insights";

export const getMessageTime = (message: IMessageResponse | IChatMessage) => {
  return new Date(message.creationTime).getTime();
}

export const transformResponseMessageToChatMessage = (messages: IMessageResponse[] = [], lastMessageTimestamp: number | null, messageType: 'history' | 'chatField' = 'history'): IChatMessage[] => {
  try {
    return messages.map((item, index) => {
      return {
        msgId: item.id,
        party: item.party ? item.party as TChatMessageType : 'Bot',
        msg: item.text || "",
        messageTime: getMessageTime(item),
        shouldDisplayTime: messageType === 'chatField' ? shouldDisplayTimeOnChatMessages(index, item, lastMessageTimestamp, 'chatField') : shouldDisplayTimeOnChatMessages(index, item, getPreviousUserOrBotMessageTimestamp(messages, index), 'history'),
        msgSequenceNumber: item.messageSequenceNumber,
        creationTime: item?.creationTime,
        sessionId: item.sessionId,
        debugInfo: item.debugInfo,
        // isHidden: messages.length > index + 1 && item.party === 'Bot' && !!item.tool && item.tool.name.includes('[UX_') && messages[index + 1].party === 'Bot',
        tool: item.tool,
        // Incorporate the grouping logic for message data if present
        data: item?.data ? groupMessageDataTasksByDate(item.data as IListMessageDataRes) : null
      } as IChatMessage;
    });
  }
  catch (e) {
    ApplicationInsightsApi.trackException(e);
    console.error(e);
    return [];
  }
};

const shouldDisplayTimeOnChatMessages = (index: number, messageItem: IMessageResponse, prevMessageTimestamp: number | null, messageType: 'history' | 'chatField') => {
  // If it's a chatField messages array (botResponse array, or createSession messages array), all these messages have the same creationTime. Therefore, display the message time only for the first one and set it to false for all the rest (when index !== 0)
  if (messageType === 'chatField' && index !== 0) return false;
  return shouldDisplayTime(getMessageTime(messageItem), prevMessageTimestamp);
}

export const shouldDisplayTime = (MessageTimestamp: number, prevMessageTimestamp: number | null) => {
  const elapsedTimeToDisplayDateTimeInMinutes = 15;
  if (prevMessageTimestamp) {
    // convert the number of milliseconds that have passed to minutes and check if it is greater than the elapsedTimeToDisplayDateTimeInMinutes var
    return ((MessageTimestamp - prevMessageTimestamp) / 60000) > elapsedTimeToDisplayDateTimeInMinutes;
  }
  return true;
}

export const getPreviousUserOrBotMessageTimestamp = (messagesArr: IMessageResponse[] | IChatMessage[], currentIndex: number): number | null => {
  for (let i = currentIndex - 1; i >= 0; i--) {
    if (messagesArr[i].party === 'Bot' || messagesArr[i].party === 'User') return getMessageTime(messagesArr[i]);
  }
  return null;
}

export const groupMessageDataTasksByDate = (msgData: IListMessageDataRes): IChatMessageData => {
  if (msgData.type === EChatMessageDataType.TASKS_LIST && msgData?.content) {
    return {
      ...msgData,
      content: orderTasksListByGroups(msgData.content)
    }
  }
  return msgData as IChatMessageData;
}

export const handleAppSurvey = (user: IUser | null, lastResponseMessage: any, messagesCounterRef: MutableRefObject<number>, session: ICreateSessionResponse | undefined, triggerSurveyOverlay: () => void) => {
  if (!messagesCounterRef || !lastResponseMessage || !user || !session) {
    return;
  }

  const isTaskCreatedOrEdited = !!lastResponseMessage.tool && !!lastResponseMessage.tool?.name &&
    (lastResponseMessage.tool?.name === endAddTaskToolName || lastResponseMessage.tool?.name === endUpdateTaskToolName);
  if (isTaskCreatedOrEdited) localStorage.setItem(didUserCreateOrEditTaskStorageKey, JSON.stringify(!!isTaskCreatedOrEdited));

  if (!user.shouldDisplayFeedbackSurvey)
    return;

  // for opening the app feedback modal when needed
  const messageCounter = messagesCounterRef.current + 1
  setItemInSessionOrLocalStorage(messagesCounterStorageKey, messageCounter);
  messagesCounterRef.current = messageCounter;

  if (!!JSON.parse(localStorage.getItem(didUserCreateOrEditTaskStorageKey) || 'null')) { // Check if user has created or edited a task
    if (messageCounter >= messagesAmountToDisplayFeedbackModal) {
      triggerSurveyOverlay();
      resetSurvey(messagesCounterRef);
    }
    else {
      const sessionStart = session?.creationTime ? new Date(session?.creationTime) : new Date();
      const timeElapsed = new Date().getTime() - sessionStart.getTime();
      const tenMinutesPassed = timeElapsed > 600000; // 600000 milliseconds = 10 minutes
      if (tenMinutesPassed) { // Check if the first run is after 10 minutes in the session
        triggerSurveyOverlay();
        resetSurvey(messagesCounterRef);
      }
    }
  }
}

const resetSurvey = (messagesCounterRef: MutableRefObject<number>) => {
  setItemInSessionOrLocalStorage(messagesCounterStorageKey, 0);
  messagesCounterRef.current = 0;
  localStorage.removeItem(didUserCreateOrEditTaskStorageKey);
};