import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { ContentFrameWrapper } from '../../../shared/components/content-frame-wrapper/ContentFrameWrapper';
import { Carousel } from 'antd';
import { CarouselRef } from 'antd/es/carousel';
import arrowRight from '../../../assets/images/next-icon.svg';
import { useTranslation } from 'react-i18next';
import './WelcomeCarousel.scss';
import { CarouselScreen1 } from './carousel-screens/CarouselScreen1';
import { CarouselScreen2 } from './carousel-screens/CarouselScreen2';
import { APP_ROUTING_PATHS, ENewUserType, signUpTypeStorageKey } from '../../constants';
import AppButton from '../../../shared/components/app-button/AppButton';
import { useNavigateWithSearchParams } from '../../../shared/utils/routingUtils';

interface IWelcomeCarouselProps {
  isTheLastElementDisplayOnTheScreenInitialValue?: boolean;
}

export const carouselLength = 2;
// this component display only after registration
export const WelcomeCarousel: FunctionComponent<IWelcomeCarouselProps> = ({ isTheLastElementDisplayOnTheScreenInitialValue = false }) => {
  const carouselRef = useRef<CarouselRef | null>();
  const [isTheLastElementDisplayOnTheScreen, setIsTheLastElementDisplayOnTheScreen] = useState(isTheLastElementDisplayOnTheScreenInitialValue);
  const initialSlide = isTheLastElementDisplayOnTheScreenInitialValue ? carouselLength - 1 : 0;
  const activeElementIndexRef = useRef<number>(initialSlide);
  const navigate = useNavigateWithSearchParams();
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      // clear carouselRef in destroy
      carouselRef.current = null;
    }
  }, [])

  const onBeforeChange = (_: number, nextSlide: number) => {
    if (nextSlide === carouselLength - 1) setIsTheLastElementDisplayOnTheScreen(true);
    else if (nextSlide !== carouselLength - 1 && isTheLastElementDisplayOnTheScreen) setIsTheLastElementDisplayOnTheScreen(false);
    if (activeElementIndexRef.current !== nextSlide) {
      activeElementIndexRef.current = nextSlide;
    }
  };

  const onClickNext = () => {
    if(isTheLastElementDisplayOnTheScreen) navigate(APP_ROUTING_PATHS.AGE_VERIFICATION);
    else carouselRef.current?.next();
  }

  const handleBack = () => {
    if(isTheLastElementDisplayOnTheScreen){
      carouselRef.current?.prev();
      return;
    }
    
    const userType = localStorage.getItem(signUpTypeStorageKey);
    switch(userType){
      case ENewUserType.OPEN:
        navigate(APP_ROUTING_PATHS.SIGN_UP);
        break;
      case ENewUserType.EXTERNAL:
        navigate(APP_ROUTING_PATHS.SIGN_UP_INSIDERS);
        break;
      default:
        navigate(APP_ROUTING_PATHS.SIGN_IN);
        break;
    }
  }

  return (
    <div
      className={`welcome-carousel-container auth-screen`}
      data-testid='welcome-carousel'
    >
      <ContentFrameWrapper>
        <AppButton tabIndex={0} className='next' onClick={onClickNext} data-testid="carousel-next-button" id="onboarding-carousel-next"><img src={arrowRight} alt={t("welcomeScreenCarouselNextScreenAltText")} /></AppButton>
        <Carousel
          className='welcome-carousel'
          beforeChange={onBeforeChange}
          infinite={false}
          ref={(carousel) => (carouselRef.current = carousel)}
          aria-label={t('welcomeCarouselLabelText')}
          dots={false}
          initialSlide={initialSlide}
        >
          <CarouselScreen1 />
          <CarouselScreen2 />
        </Carousel>
      </ContentFrameWrapper>
      <AppButton onClick={() => handleBack()} className='back-link' id="onboarding-carousel-back">{t('registerScreenBackButtonText')}</AppButton>
    </div>
  )
}