import { useEffect } from "react";
import { disallowedCountries, isDesktopView, supportedCountries, supportedCountriesInternal } from "../../utils/utils";
import { Controller, useForm, useWatch } from "react-hook-form";
import { AppSelect } from "../app-select/AppSelect";
import PhoneInput, { getCountries, getCountryCallingCode, isValidPhoneNumber } from "react-phone-number-input/input";
import en from 'react-phone-number-input/locale/en.json'
import './AppPhoneControl.scss';

interface IProps {
    className: string;
    isInternalUser: boolean;
    errorText: string;
    onPhoneValid: (isValid: boolean) => void;
    onChange: (value: IOnChangeEvent) => void;
}

interface IFormData {
    phone: string;
    country: string;
}

export interface IOnChangeEvent {
    phone: string;
    country: string;
}



export const AppPhoneControl = ({ className, isInternalUser, errorText = '', onPhoneValid ,onChange }: IProps) => {
    const supportedCountriesList = isInternalUser ? supportedCountriesInternal : supportedCountries;

    const { control, setFocus, formState, reset, setValue } = useForm<IFormData>({
        defaultValues: {
            phone: '',
            country: 'US'
        }
    });
    
    const phoneValue = useWatch({ control, name: 'phone'});
    const countryValue = useWatch({ control, name: 'country'});

    useEffect(() => {
        if (isDesktopView()) setFocus(`phone`);
    }, [setFocus]);

    useEffect(() => {
        if (errorText.length > 0) reset();
        if (isDesktopView()) setFocus(`country`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorText])

    const handleChangeCountry = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setValue('country', event.target.value, {shouldValidate: true});
        setValue('phone', "", {shouldValidate: true});
    }

    const getCountriesSorted = () => {
        const allowedCountries = getCountries().filter(c => !disallowedCountries.includes(c));
        const supportedCountries = allowedCountries.filter(c => supportedCountriesList.includes(c)).sort((a, b) => en[a].localeCompare(en[b]));
        return supportedCountries;
    }


    useEffect(() => {
        onChange({phone: phoneValue, country: countryValue});
        onPhoneValid(formState.isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [phoneValue, countryValue]);

    return (
        <div className={`app-phone-control ${className}`}>
            <AppSelect className='app-phone-control-select' value={countryValue} onChange={handleChangeCountry} options={getCountriesSorted().map(c => ({ value: c, label: `+${getCountryCallingCode(c)} ${en[c]}`, selectedLabel: `+${getCountryCallingCode(c)}`, className: `country-initials-${c.toLocaleLowerCase()}` }))} />
            {(supportedCountriesList.includes(countryValue)) && <>
                <Controller
                    name='phone'
                    control={control}
                    rules={{ validate: (value) => isValidPhoneNumber(`${value}`) }}
                    render={({ field: { onChange, value } }) => (
                        <PhoneInput
                            id={`app-phone-control-input`}
                            data-testid={`app-phone-control-input`}
                            className={`app-phone-control-input`}
                            placeholder='XXX-XXX-XXXX'
                            value={value}
                            onChange={onChange}
                            maxLength={15 - (getCountryCallingCode(countryValue as any)?.length || 0)}
                            country={countryValue as any}
                        />
                    )}
                />
                {!!errorText.length && <small className='error' data-testid="app-phone-control-error">{errorText}</small>}
            </>}
        </div>
    )

}