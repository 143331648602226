import React, { FunctionComponent, useRef, useState } from 'react';
import { getDateAndTimeWithoutYearAndWithoutDay, getDateAndTimeWithoutYearAndWithoutDayWithoutTime, getDateAndTimeWithoutYearWithoutTime, getTimeByDate } from '../../../../../../../shared/utils/dateFormat';
import dayjs from 'dayjs';
import calendarIcon from '../../../../../../../assets/images/single-task/calendar-due-date-dark.svg';
import calendarIconOverdue from '../../../../../../../assets/images/calendar-due-date-brand-4.svg';
import { ReactComponent as PriorityStarIconComponent } from '../../../../../../../assets/images/priority-star-on.svg';
import { store, useAppDispatch, useAppSelector } from '../../../../../../../app/store';
import { ETaskFormType, ETaskSource, ETaskStatus } from '../../../../../resizable-container/stage-container/stage-tasks/stageTasks.interface';
import { getTasksListReqAction, setSelectedMainTaskForEditing, setShouldOpenAddEditTaskFrom } from '../../../../../resizable-container/stage-container/stage-tasks/stageTasks.store';
import { ApplicationInsightsApi } from '../../../../../../../application-insights';
import { getFirstLabelColor, getTimeRange, isOverdueDate } from '../TasksList.utils';
import TaskDueActionButton from './task-action-buttons/TaskDueActionButton';
import TaskLaterActionButton from './task-action-buttons/TaskLaterActionButton';
import TaskScheduleActionButton from './task-action-buttons/TaskScheduleActionButton';
import { ETaskViewType, ISingleTaskItemContainerChildrenProps } from './SingleTaskItemContainer';
import './TasksListSingleItem.scss';
import { useTranslation } from 'react-i18next';
import workTimeIcon from '../../../../../../../assets/images/single-task/task_work_time_icon.svg';
import recurrenceIcon from '../../../../../../../assets/images/single-task/task_recurrence_icon.svg';
import quickWinIcon from '../../../../../../../assets/images/single-task/task_quick_win_icon.svg';
import separationDot from '../../../../../../../assets/images/single-task/separation_dot.svg';
import { isMobileDevice } from '../../../../../../../shared/utils/isMobileDevice';
import { setPlannerCurrentTaskPlacement } from '../../../../../resizable-container/stage-container/stage-planner/stagePlanner.store';
import { ESoundType, getExternalEventSourceDetails, handleAppPlaySound } from '../../../../../../../shared/utils/utils';
import TaskDeleteActionButton from './task-action-buttons/TaskDeleteActionButton';
import AppButton from '../../../../../../../shared/components/app-button/AppButton';

const TasksListSingleItem: FunctionComponent<ISingleTaskItemContainerChildrenProps> = ({
  singleTask,
  className,
  shouldHideOnComplete,
  onClick,
  onUpdateTask,
  actionOverlaysPositionStyle,
  shouldFetchUpdatedListAfterUpdateSingleTask = true,
  shouldDisplayActionButtons = false,
  isPriority,
  setIsPriority,
  shouldDisplayFirstTagColor = false,
  showParentTaskDetails = false,
  viewType = undefined,
  testid = ''
}) => {
  const dispatch = useAppDispatch();
  const completeTimerRef = useRef<NodeJS.Timeout | null>(null);
  const removeTasksTime = 2000;
  // to hide a complete task if in chat list
  const [isHidden, setIsHidden] = useState(false);
  const { t } = useTranslation();
  const [isStopPropagation, setIsStopPropagation] = useState(true);
  const isMobile = isMobileDevice();
  const {currentTaskPlacement} = useAppSelector(store => store.StagePlannerReducer);

  const updateTasksList = () => {
    dispatch(getTasksListReqAction());
  }

  const handleCheckbox = (e: React.FormEvent<HTMLInputElement>) => {
    const status = (e.target as HTMLInputElement).checked ? ETaskStatus.DONE : ETaskStatus.NOT_STARTED
    if (status === ETaskStatus.DONE) handleAppPlaySound(ESoundType.TASK_COMPLETED);
    onUpdateTask({ status: status })
    if (completeTimerRef.current) clearTimeout(completeTimerRef.current);
    completeTimerRef.current = setTimeout(() => {
      if (singleTask.status !== ETaskStatus.SCRATCHPAD) updateTasksList();
      if ((e.target as HTMLInputElement).checked) {
        if (shouldHideOnComplete) setIsHidden(!isHidden);
        if (currentTaskPlacement?.id === singleTask.id) dispatch(setPlannerCurrentTaskPlacement(null));
      }
    }, removeTasksTime);
  }

  // if there is an onClick prop, this is a subTask and the onOpenEditTaskFormModal function won't be called.
  const onOpenEditTaskFormModal = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    // for tasks that are multi work time tasks or recurring instances, we need to open the parent task for editing
    if (singleTask.isMultiWorkTimeTask) {
      const parentTask = store.getState().StageTasksReducer.allTasks.find(t => t.id === singleTask.parentId);
      if (parentTask) dispatch(setSelectedMainTaskForEditing(parentTask));
    } else if (singleTask.isRecurrenceInstance) {
      const parentTask = store.getState().StageTasksReducer.allTasks.find(t => t.id === singleTask.id && !t.isRecurrenceInstance);
      if (parentTask) dispatch(setSelectedMainTaskForEditing(parentTask));
    } else {
      dispatch(setSelectedMainTaskForEditing(singleTask));
    }
    dispatch(setShouldOpenAddEditTaskFrom(singleTask.isEvent ? ETaskFormType.Event : ETaskFormType.Task));
  }

  const getDueDateDisplayText = () => {
    try {
      const today = dayjs(new Date());
      const isDueDateToday = dayjs(new Date(singleTask.dueDate!)).isSame(today, 'day');
      const isDueDateTimeElevenFiftyNine = dayjs(singleTask.dueDate).isSame(dayjs(singleTask.dueDate).endOf('day'), 'minute');
      if (isDueDateToday && isDueDateTimeElevenFiftyNine) {
        if (viewType === ETaskViewType.MY_PLAN_DUE) return "";
        return getDateAndTimeWithoutYearAndWithoutDayWithoutTime(new Date(singleTask.dueDate!).toISOString());
      };
      if (isDueDateToday && viewType === ETaskViewType.MY_PLAN_DUE) return getTimeByDate(singleTask.dueDate!);
      if (isDueDateTimeElevenFiftyNine) return getDateAndTimeWithoutYearWithoutTime(new Date(singleTask.dueDate!).toISOString());
      return getDateAndTimeWithoutYearAndWithoutDay(new Date(singleTask.dueDate!).toISOString());
    } catch (error) {
      ApplicationInsightsApi.trackException(error);
      return ""
    }
  }

  const handleClickEvent = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    // when this component is displayed on the calendar placement unscheduled tasks list return here if click target was input element or label (which is the checkbox to mark task as complete)
    if (viewType === ETaskViewType.CALENDAR_PLACEMENT && ((e.target as HTMLElement).tagName === 'INPUT' || (e.target as HTMLElement).tagName === 'LABEL')) return;

    if (viewType === ETaskViewType.IN_CHAT_TASK_LIST) return;
    if (viewType === ETaskViewType.CALENDAR_PLACEMENT) {
      dispatch(setPlannerCurrentTaskPlacement(singleTask));
      return;
    }
    if (!isStopPropagation) return;
    e.preventDefault();
    if (!!onClick) {
      onClick(e);
    }
    else onOpenEditTaskFormModal(e);
  }

  return (
    <div className={`tasks-list-item single-task-container--view-type-${viewType} ${className ? className : ''} ${singleTask?.isEvent ? 'tasks-list-item--event' : ''} ${isHidden ? 'd-none' : 'displayed'} ${singleTask?.parentId ? 'subtask-item' : ''} ${isMobile ? 'tasks-list-item--calender-placement' : ''} ${currentTaskPlacement && currentTaskPlacement.id === singleTask.id ? 'tasks-list-item--calendar-placement' : ''} ${singleTask?.status === ETaskStatus.DONE ? 'completed-task' : ''}`}
      data-testid={`${testid}-${singleTask.name}`}
      onClick={handleClickEvent}>
      <section className="task-item-left">
        {shouldDisplayFirstTagColor && <div className='task-first-label-color' style={{ backgroundColor: getFirstLabelColor(singleTask) }} />}
        {viewType !== ETaskViewType.MY_WEEK && viewType !== ETaskViewType.SCRATCHPAD && <label onMouseEnter={() => setIsStopPropagation(false)} onMouseLeave={() => setIsStopPropagation(true)} onClick={e => { if (isStopPropagation) e.stopPropagation() }} className={`task-complete-label ${singleTask?.isEvent ? 'visibility-hidden' : ''}`}>
          <input className='complete-checkbox' type="checkbox" onInput={handleCheckbox} defaultChecked={singleTask?.status === ETaskStatus.DONE} />
        </label>}
        <div className='open-edit-task-container'>
          <div className='task-name-container'>
            <span className='task-name'>{singleTask.name}</span>
            {singleTask?.isEvent && !!singleTask?.source && singleTask?.source !== ETaskSource.Internal && <span className='external-event-source'>{getExternalEventSourceDetails(singleTask.source)}</span>}
            {viewType !== ETaskViewType.CALENDAR_PLACEMENT && <div className='task-due-and-sub-task-info-container'>
              {!!singleTask.dueDate && <>
                {isOverdueDate(singleTask.dueDate) ?
                  <span className='task-name task-name--sub-title task-name--overdue'>
                    <img src={calendarIconOverdue} alt="calendar" />
                    {t('overdue')}
                  </span>
                  : getDueDateDisplayText().length > 0 && <span className="task-name task-name--sub-title">
                    <img src={calendarIcon} alt="calendar" />
                    {getDueDateDisplayText().toUpperCase()}
                  </span>}
              </>}
              <img className='separator-dot' src={separationDot} alt="dot" />
              {singleTask.siblingsData && <span className='task-name task-name--sub-title task-name--siblings-info'>{`${singleTask.siblingsData.subTaskOrder} of ${singleTask.siblingsData.totalSiblings}`}</span>}
            </div>}
          </div>
        </div>
      </section>
      <section className='task-item-right'>
        {!isMobile && shouldDisplayActionButtons && viewType !== ETaskViewType.MY_WEEK &&
          <div className='task-actions-container'>
            <TaskDueActionButton task={singleTask} className="--desktop" onUpdateTask={onUpdateTask} actionOverlaysPositionStyle={actionOverlaysPositionStyle} />
            <TaskScheduleActionButton task={singleTask} className="--desktop" onUpdateTask={onUpdateTask} actionOverlaysPositionStyle={actionOverlaysPositionStyle} />
            <TaskLaterActionButton task={singleTask} className="--desktop" onUpdateTask={onUpdateTask} />
            {/* add delete action for scratchpad task */}
            {singleTask.status === ETaskStatus.SCRATCHPAD && <TaskDeleteActionButton task={singleTask} className="--desktop" onUpdateTask={onUpdateTask} />}
          </div>
        }
        <div className='work-time-and-icons-container'>
          {<p className={`task-item-work-time  ${viewType !== ETaskViewType.MY_PLAN_SCHEDULED || !singleTask.workTime ? " task-item-work-time--hidden" : ""} task-name`}><img src={workTimeIcon} alt="work-time-icon" />{!!singleTask.workTime ? getTimeRange(new Date(singleTask.workTime), singleTask.duration) : "No Data"}</p>}
          {viewType !== ETaskViewType.MY_WEEK && viewType !== ETaskViewType.SCRATCHPAD && <div className='task-icons-container'>
            {(!!singleTask.workTime || !!singleTask.workBlockId) && viewType !== ETaskViewType.MY_PLAN_SCHEDULED && <img src={workTimeIcon} alt="work-time-icon" />}
            {!!singleTask.workTimeRecurrenceType && <img src={recurrenceIcon} alt="recurrence-icon" />}
            {!!singleTask.quickWin && <img src={quickWinIcon} alt="quick-win-icon" />}
          </div>}
        </div>
        {viewType !== ETaskViewType.MY_WEEK && viewType !== ETaskViewType.SCRATCHPAD && !singleTask?.isEvent &&
          <AppButton type="button" id="task-priority-toggle" className='priority' onClick={(e) => { e.stopPropagation(); setIsPriority(!isPriority); onUpdateTask({ priority: !isPriority }) }}>
            <PriorityStarIconComponent className={`single-task-priority-icon single-task-priority-icon--${isPriority ? 'on' : 'off'}`} />
          </AppButton>
        }
      </section>
    </div>
  )
}

export default TasksListSingleItem;
