import React, { FunctionComponent, MouseEvent } from 'react'
import { IMessageDataTask } from '../../../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.interface';
import SassVariables from "../../../../../styles/style.module.scss";
import { useTranslation } from 'react-i18next';
import { useDrag } from 'react-dnd';
import { EDragAndDropType } from '../../../../../shared/utils/utils';
import { ICalendarEvent } from '../CalendarDay';
import { IWorkBlockDropResult } from '../CalendarEvent';
import { useAppSelector } from '../../../../../app/store';

interface IWorkBlockTaskItem {
  task: IMessageDataTask;
  onClickTask?: (e:MouseEvent<HTMLElement, any>) => void;
  eventsFlatArray: ICalendarEvent[];
  dayIndex: number;
  divideEventByGroups: (events: ICalendarEvent[]) => void;
  convertSingleItemToCalendarEvent(el: IMessageDataTask): ICalendarEvent;
}

const WorkBlockTaskItem: FunctionComponent<IWorkBlockTaskItem> = ({
  onClickTask,
  task,
  eventsFlatArray,
  dayIndex,
  divideEventByGroups,
  convertSingleItemToCalendarEvent
}) => {
  const { currentTaskPlacement } = useAppSelector(store => store.StagePlannerReducer);
  const { t } = useTranslation();

  const getBackgroundColor = () => {
    if (!task?.tags || task?.tags.length === 0) return SassVariables.whiteColor;
    else if (!task?.tags[0]?.color || task?.tags[0]?.color === 'transparent') return SassVariables.whiteColor;
    return task?.tags[0]?.color;
  }

  const backgroundColor = getBackgroundColor();

  const [{ opacity, cursor, transition }, dragRef] = useDrag({
    type: EDragAndDropType.CALENDAR_EVENT,
    item: { event: convertSingleItemToCalendarEvent(task), isComeFromWorkBlock: true }, // Data about the event being dragged
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.5 : 1,
      cursor: monitor.isDragging() ? 'move' : 'pointer',
      transition: monitor.isDragging() ? 'none' : 'all 0.2s ease',
    }),
    canDrag: () => currentTaskPlacement?.id !== task.id,
    end(_, monitor) {
      const dropResult: IWorkBlockDropResult | null = monitor.getDropResult();
      if (monitor.didDrop()) {
        // if dropped in the same workBlock in the current day, return for now (in the future this block will used for reordering the tasks inside the workBlock).
        if (dropResult?.droppedWorkBlockId === task.workBlockId && dropResult?.dayIndex === dayIndex) return;
        // if dropped in different workBlock or inside the calendar itself, remove the event from the current workBlock relatedTasks array
        const tempEventsArr = [...eventsFlatArray];
        const draggedTaskWorkBlockItem = tempEventsArr.find(e => e.id === task?.workBlockId);
        if (draggedTaskWorkBlockItem) {
          draggedTaskWorkBlockItem.relatedTasks = draggedTaskWorkBlockItem.relatedTasks.filter(t => t.id !== task.id);
          divideEventByGroups(tempEventsArr);
        }
      }
    },
  });

  return (
    <div ref={dragRef} onClick={onClickTask} className={`work-block-task-item ${backgroundColor === SassVariables.whiteColor ? 'work-block-task-item--bg-white' : ''}`} style={{ backgroundColor: backgroundColor, borderColor: backgroundColor, opacity, cursor, transition }}>
      <span className={`calendar-work-block-task-name`}>{task?.name || t("newTaskDefaultName")}</span>
    </div>
  )
}

export default WorkBlockTaskItem
