import React, { CSSProperties, Dispatch, FunctionComponent, MouseEvent, SetStateAction } from 'react'
import { ICalendarEvent, IDropResult } from './CalendarDay'
import { ETaskFormType, ETaskSource, ETaskStatus, IMessageDataTask } from '../../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.interface';
import { store, useAppDispatch, useAppSelector } from '../../../../app/store';
import { onPlaceUnscheduledTask } from '../../plan.utils';
import { EPlannerMode } from '../../../chat-wrapper/resizable-container/stage-container/stage-planner/stagePlanner.store';
import { setShouldOpenWorkBlockDetails, setWorkBlockForEdit } from '../../../chat-wrapper/resizable-container/stage-container/work-block-details/workBlock.store';
import { areDatesEqual, EDragAndDropType, getExternalEventSourceDetails, getWorkBlockOrder, isTaskAssignToThisWorkBlock } from '../../../../shared/utils/utils';
import { setSelectedMainTaskForEditing, setShouldOpenAddEditTaskFrom } from '../../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.store';
import { useDrag, useDrop } from 'react-dnd';
import WorkBlockTasksList from './work-block-tasks-list/WorkBlockTasksList';
import { EPlanDayCardDisplayType } from '../../plan-day-card/PlanDayCard';
import recurrenceIcon from '../../../../assets/images/single-task/task_recurrence_icon.svg';
import SassVariables from "../../../../styles/style.module.scss";

interface ICalendarEventProps {
  event: ICalendarEvent;
  isCalenderDayClickable: boolean;
  shouldShowHourText: boolean;
  calendarDayContainerWidth: number;
  playViewType: EPlanDayCardDisplayType;
  divideEventByGroups: (events: ICalendarEvent[]) => void;
  eventsFlatArray: ICalendarEvent[];
  dayIndex: number;
  calendarEventDrop: (dargItem: IDragItem, hours: number, minutes: number) => IDropResult;
  convertSingleItemToCalendarEvent(el: IMessageDataTask): ICalendarEvent;
  updateDragEventApiRequest: (dragItem: IDragItem, updatedWorkTime: Date | null, relatedWorkBlockId?: string, relatedWorkBlockOrder?: number, relatedWorkBlockInstance?: Date | null) => void
  setDroppingProcessCounter: Dispatch<SetStateAction<number>>
}

export interface IDragItem {
  event: ICalendarEvent;
  isComeFromWorkBlock?: boolean;
}

export interface IWorkBlockDropResult extends IDropResult {
  droppedWorkBlockId: string;
}

const CalendarEvent: FunctionComponent<ICalendarEventProps> = ({
  event,
  dayIndex,
  divideEventByGroups,
  eventsFlatArray,
  isCalenderDayClickable,
  shouldShowHourText,
  calendarDayContainerWidth,
  playViewType,
  calendarEventDrop,
  convertSingleItemToCalendarEvent,
  updateDragEventApiRequest,
  setDroppingProcessCounter
}) => {
  const { allTasks } = useAppSelector(store => store.StageTasksReducer);
  const { plannerMode, currentTaskPlacement } = useAppSelector(store => store.StagePlannerReducer);
  const dispatch = useAppDispatch();
  const defaultLeftPosition = 40;
  const reductionFromContainerWidth = shouldShowHourText ? 40 : 0;
  const marginBetweenEvents = 2;

  const [{ opacity, cursor, transition }, dragRef] = useDrag({
    type: EDragAndDropType.CALENDAR_EVENT,
    item: { event }, // Data about the event being dragged
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.5 : 1,
      cursor: monitor.isDragging() ? 'move' : 'pointer',
      transition: monitor.isDragging() ? 'none' : 'all 0.2s ease',
    }),
    canDrag: () => !event.isRecurring && currentTaskPlacement?.id !== event.id,
    end(draggedItem, monitor) {
      const dropResult: IDropResult | null = monitor.getDropResult();
      // if dropped in different day, remove the event from the current day (else, the event is already updated in the current day inside the drop function)
      if (monitor.didDrop() && dropResult?.dayIndex !== dayIndex) {
        divideEventByGroups(eventsFlatArray.filter(e => e.id !== draggedItem.event.id));
      }
    },
  });

  const drop = (dragItem: IDragItem) => {
    if(event.isWorkBlock) return dropInToWorkBlock(dragItem);
    return calendarEventDrop(dragItem, event.start.getHours(), event.start.getMinutes())
  }

  const updateLocalDroppedWorkBlockEvent = (task: IMessageDataTask, taskWorkBlockOrder: number, taskWorkBlockInstance: Date | null) => {
      const updatedTask = { 
        ...task,
        workBlockId: event?.id,
        workBlockInstance: taskWorkBlockInstance,
        workBlockOrder: taskWorkBlockOrder,
        workTime: null,
        workTimeRecurrenceType: null,
        workTimeReminder: null,
      }
        const tempEventsArr = [...eventsFlatArray];
        const workBlockEvent = tempEventsArr.find(e => e.id === event.id);
        if (workBlockEvent) {
          workBlockEvent.relatedTasks.push(updatedTask);
          divideEventByGroups([...tempEventsArr]);
        }
  }

  const dropInToWorkBlock = (dragItem: IDragItem): IWorkBlockDropResult => {
    setDroppingProcessCounter(prev => prev + 1);
    const task = allTasks.find(t => t.id === dragItem.event.id);
    const taskWorkBlockInstance = event.isRecurring ? event.start : null;
    // if the task is not already assigned to this workBlock, update the task and the workBlock
    if (!!task && !isTaskAssignToThisWorkBlock(event.id, taskWorkBlockInstance || "", task?.workBlockId, task?.workBlockInstance)) {
      const taskWorkBlockOrder = getWorkBlockOrder(event.relatedTasks[event.relatedTasks.length - 1]?.workBlockOrder);
      updateLocalDroppedWorkBlockEvent(task, taskWorkBlockOrder, taskWorkBlockInstance);
      updateDragEventApiRequest(dragItem, null, event.id, taskWorkBlockOrder, taskWorkBlockInstance);
    }
    return {dayIndex, droppedWorkBlockId: event.id}
  }

  const [{ isDraggableItemOverClassName }, dropRef] = useDrop({
    accept: EDragAndDropType.CALENDAR_EVENT,
    drop: (dragItem: IDragItem) => drop(dragItem),
    collect: (monitor) => ({
      isDraggableItemOverClassName: monitor.isOver() && !monitor.getItem().event.isEvent && !monitor.getItem().event.isWorkBlock ? 'draggable-item-is-over' : '',
    }),
  });

  const onPlaceTaskInsideWorkBlock = (workBlockEvent: ICalendarEvent) => {
    onPlaceUnscheduledTask(workBlockEvent.start, workBlockEvent.id, workBlockEvent.title, workBlockEvent.isRecurring, workBlockEvent.relatedTasks.length ? getWorkBlockOrder(workBlockEvent.relatedTasks[workBlockEvent.relatedTasks.length - 1]?.workBlockOrder) : 0);
  }

  const onClickWorkBlockEvent = (workBlockEvent: ICalendarEvent) => {
    if (plannerMode === EPlannerMode.TIMEPICKER) {
      onPlaceTaskInsideWorkBlock(workBlockEvent);
      return;
    }
    const workBlock = store.getState().StageTasksReducer.allWorkBlocks.find(w => w.id === workBlockEvent.id && areDatesEqual(w.workTime, workBlockEvent.start));
    if (workBlock) {
      dispatch(setWorkBlockForEdit(workBlock));
      dispatch(setShouldOpenWorkBlockDetails(true));
    }
  }

  const handleEventClick = (clickEvent: MouseEvent<HTMLElement, any>, event: ICalendarEvent, workBlockTask?: IMessageDataTask) => {
    clickEvent.stopPropagation();
    if (!isCalenderDayClickable) return;
    if (((plannerMode === EPlannerMode.UNSCHEDULEDTASKSPLACER) && currentTaskPlacement)) {
      if (event.isWorkBlock) {
        onPlaceTaskInsideWorkBlock(event);
        return;
      }
      onPlaceUnscheduledTask(event.start);
      return;
    };
    if (workBlockTask && plannerMode !== EPlannerMode.TIMEPICKER) {
      dispatch(setSelectedMainTaskForEditing(workBlockTask));
      dispatch(setShouldOpenAddEditTaskFrom(ETaskFormType.Task));
      return
    }
    if (!event.isWorkBlock && plannerMode === EPlannerMode.TIMEPICKER) {
      onPlaceUnscheduledTask(event.start);
      return;
    }
    if (event.isWorkBlock) onClickWorkBlockEvent(event);
    else {
      const task = store.getState().StageTasksReducer.allTasks.find(t => event.parentId ? t.id === event.parentId : t.id === event.id);
      if (task) {
        dispatch(setSelectedMainTaskForEditing(task));
        dispatch(setShouldOpenAddEditTaskFrom(event.isEvent ? ETaskFormType.Event : ETaskFormType.Task));
      }
    }
    clickEvent.preventDefault();
    clickEvent.stopPropagation();
  }

  const calcEventLeft = () => {
    const leftPosition = shouldShowHourText ? defaultLeftPosition : 0;
    if (event.columnOffset === 0) return event.columnOffset! * (calendarDayContainerWidth - reductionFromContainerWidth) / event.totalColumns! + leftPosition;
    return event.columnOffset! * (calendarDayContainerWidth - reductionFromContainerWidth) / event.totalColumns! + leftPosition;
}

  const getEventStyle = (event: ICalendarEvent): CSSProperties => {
    return ({
      position: 'absolute',
      top: event.top,
      left: calcEventLeft(),
      height: event.height,
      width: (((calendarDayContainerWidth - reductionFromContainerWidth) / event.totalColumns!) - marginBetweenEvents) + 'px',
      transition,
      opacity,
      cursor
    })
  }

  return (
    <div
      key={event.id}
      ref={(node) => {
        dragRef(node);
        dropRef(node);
      }}
      className={`calendar-event calendar-event--${event.durationType} ${event?.isWorkBlock ? 'calendar-event--work-block' : ''}`}
      onClick={(e) => { e.stopPropagation(); handleEventClick(e, event) }}
      style={getEventStyle(event)}
    >
      <div className={`calendar-event-inner-container calendar-event-inner-container--${event.durationType} ${isDraggableItemOverClassName}`} style={{ backgroundColor: event.backgroundColor, border: `${event.backgroundColor === "#FFF" ? "1px solid " + SassVariables.MaxDarkColor : 'none'}` }}>
        <div className="event-text-container">
          <div className='title-container'>
            <h1 className={`calendar-event-title calendar-event-title--${playViewType === EPlanDayCardDisplayType.MY_DAY ? "my-day" : "my-week"} ${event.status === ETaskStatus.DONE ? event.titleColor !== "white" ? 'completed-task completed-task--grey' : 'completed-task' : ''}`} style={{ color: event.titleColor }}>{event.title}</h1>
          </div>
          {!event?.isWorkBlock && event.source !== ETaskSource.Internal && <span className='calendar-event-addition-text' style={{ filter: event.backgroundColor !== 'transparent' && event.backgroundColor !== '#FFF' ? 'brightness(5)' : 'none' }}>{getExternalEventSourceDetails(event.source)}</span>}
          {event?.isWorkBlock && 
            <WorkBlockTasksList 
              workBlock={event} 
              onClickWorkBlockTask={handleEventClick} 
              planViewType={playViewType} 
              relatedTasks={event.relatedTasks}
              eventsFlatArray={eventsFlatArray}
              dayIndex={dayIndex}
              divideEventByGroups={divideEventByGroups} 
              convertSingleItemToCalendarEvent={convertSingleItemToCalendarEvent}
            />
          }
        </div>
        {!event?.isWorkBlock &&
          <div className='calendar-event-addition-info-container'>
            <img className={`${!event.isRecurring ? ' visibility-hidden' : ''}`} src={recurrenceIcon} alt="recurrence-icon" style={{ filter: event.backgroundColor !== 'transparent' && event.backgroundColor !== '#FFF' ? 'brightness(5)' : 'none' }} />
          </div>
        }
      </div>
    </div>
  )
}

export default CalendarEvent