import { IUser } from "../../app/auth/auth.interfaces";
import { noRedirectLocalStorageKey } from "../../app/auth/auth.utils";
import { ENewUserType, plannerModeOnLocalStorageKey, recentTaskTagsLocalStorageKey, selectedFilterTypeLocalStorageKey, shouldShowDiscordToastInCurrentSessionKey, signUpTypeStorageKey, turnsCounterStorageKey, userInfoLocalStorageKey } from "../../app/constants";
import { getItemFromSessionOrLocalStorage } from "./getItemFromSessionOrLocalStorage";
import { clearSearchParamsFromCurrentUrl } from "./routingUtils";

export const handleLogOut = (shouldReload=true) => {
  const user = getItemFromSessionOrLocalStorage<IUser | null>(userInfoLocalStorageKey);
  const signUpType = user?.signUpType || (localStorage.getItem(signUpTypeStorageKey) || ENewUserType.EXTERNAL);
  sessionStorage.clear();
  Object.keys(localStorage).forEach(key => {
    if (![recentTaskTagsLocalStorageKey, noRedirectLocalStorageKey, selectedFilterTypeLocalStorageKey, plannerModeOnLocalStorageKey, turnsCounterStorageKey, shouldShowDiscordToastInCurrentSessionKey].includes(key)) {
      localStorage.removeItem(key);
    }
  });
  localStorage.setItem(signUpTypeStorageKey, signUpType);
  clearSearchParamsFromCurrentUrl();
  if(shouldReload) window.location.reload();
}