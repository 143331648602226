export const tokenLocalStorageKey = "maximalLearningToken"
export const refreshTokenLocalStorageKey = "maximalLearningRefreshToken"
export const userInfoLocalStorageKey = "maximalLearningUserInfo"
export const shouldStayLoggedInLocalStorageKey = "maximalLearningShouldStayLoggedIn"
export const loginTypeStorageKey = "maximalLearningLoginType"
export const operatingSystemLocalStorageKey = "maximalLearningOperatingSystem"
export const selectedOptionPerMsgIdKey = "selectedOptionPerMsgId";
export const chatSessionIdSessionStorageKey = "sessionId";
export const redirectUrlStorageKey = "redirectUrl";
export const sessionIdRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
export const maximalLearningDomain = "https://www.maximallearning.com";
export const privacyPolicyLink = `${maximalLearningDomain}/privacy`;
export const termsOfServiceLink = `${maximalLearningDomain}/tos`;
export const contactUsLink = `${maximalLearningDomain}/contact`;
export const discordLink = `https://discord.com/invite/kS4Q9Wq393`;
export const discordLinkForOpenUsers = `https://discord.gg/HVbuMSSyZD`;
export const messageTestId = 'message';
export const dislikeFeedbackParam = 'dislikeFeedback';
export const settingsMenuParam = 'settingsMenu';
export const chatHistoryParam = 'chatHistory';
export const userPhoneLength = 20;
export const numberOfMessagesToFetch = 100;
export const inviteFriendsLink = (userId: string) => `https://join.maximallearning.com/?utm_medium=inapp&utm_source=referral&utm_term=`+userId;
export const messagesCounterStorageKey = "messagesCounter";
export const messagesAmountToDisplayFeedbackModal = 20;
export const didUserCreateOrEditTaskStorageKey = "didUserCreateOrEditTask";
export const stageParam = 'stageContent';
export const colorPalette = ["transparent", "#F9BB42", "#FF8A48", "#F1543F", "#E64AD6", "#B347F5", "#7748FF", "#2F43F2", "#3FB0E1", "#19C695", "#8FB300"];
export const endAddTaskToolName = "[END_ADD_TASK]";
export const endUpdateTaskToolName = "[END_UPDATE_TASK]";
export const sessionSourceTypeStorageKey = "sessionSourceTypeId";
export const surveyPrepopulatedOptionText = "Can I ask you to fill out a quick survey to give us feedback on this latest version?";
export const taskDueDateDefaultTime = "11:59PM";
export const animationDurationTaskFormInMs = 800;
export const recentTaskTagsLocalStorageKey = 'maximalLearningRecentTaskTags';
export const signUpTypeStorageKey = 'maximalLearningSignUpType';
export const taskFormParam = "taskForm";
export const bodyInputFocusedClassName = "input-focused";
export const chatInputId = "student-input";
export const isChatInputFocusedLocalStorageKey = "studentInputFocused";
export const ageGateLocalStorageKey = "ageGatePass"
export const registrationParametersStorageKey = 'registrationParameters';
export const slideUpFullScreenMobilePopoverClassName = 'slideIn-full-screen-mobile';
export const slideUpHalfScreenMobilePopoverClassName = 'slideIn-half-screen-mobile';
export const subTaskOverlayPopoverClassName = 'sub-task-overlay scroll-y-container-hidden-scroll-bar ' + slideUpFullScreenMobilePopoverClassName;
export const userPhoneNumberLocalStorageKey = "userPhoneNumber";
export const userPhoneCountryTwoLetterCodeLocalStorageKey = "userPhoneCountryTwoLetterCode";
export const workBlockDetailsParam = "workBlockDetails";
export const workBlockDetailsFormParam = "workBlockDetailsForm";
export const timePickerContextOverlayDefaultWidth = 350;
export const selectedFilterTypeLocalStorageKey = "selectedFilterType";
export const plannerModeOnLocalStorageKey = "plannerModeOn";
export const isSignUpLocalStorageKey = "isSignUp";
export const isLoginFromMagicLinkLocalStorageKey = "isLoginFromMagicLink";
export const productTourDataAttrChat = "chat-nav";
export const productTourDataAttrScratchpad = "scratchpad-nav";
export const productTourDataAttrTasks = "tasks-nav";
export const productTourDataAttrPlan = "plan-nav";
export const productTourDataAttrUserAvatarMobile = "user-avatar-mobile";
export const productTourDataAttrUserAvatarDesk = "user-avatar-desk";
export const routeParamsLocalStorageKey = "routeParams";
export const popUpToastOverlayClassName = "app-toast-overlay-container";
export const shouldShowDiscordToastInCurrentSessionKey = "shouldDisplayDiscordToastCurrentSession"
export const discordToastId = "discord-toast";
export const turnsCounterStorageKey = "maximalLearningTurnsCounter";
export const turnsAmountToDisplayDiscordToast = 10;

export const APP_ROUTING_PATHS = {
    PAGE_404: 'page-404',
    HOME_PAGE: 'home-page',
    HOME: '/app',
    AUTHLAYOUT: '/auth',
    LOGOUT: '/auth/logout',
    SSO: '/auth/sso',
    SSO_MAGIC_LINK: '/auth/sso/link',
    SSO_MAGIC_LINK_INSIDERS: '/auth/sso/link/insiders',
    SSO_FAILED: '/auth/sso-error',
    STAY_LOGGED_IN: '/auth/stay-logged-in',
    REGISTER: '/auth/register',
    ADD_PHONE_REGISTER: '/auth/add-phone',
    CHAT: 'chat',
    REDIRECT: '/redirect',
    SESSION_SOURCE_TYPE: '/n/:sessionSourceTypeId',
    SIGN_UP_OPEN: '/auth/signup1',
    AGE_VERIFICATION: '/auth/verify-age',
    WAIT_LIST: '/auth/wait-list',
    PHONE_VERIFICATION: '/auth/phone-verification',
    SIGN_IN: '/auth/signin',
    SIGN_UP: '/auth/signup',
    SIGN_UP_INSIDERS: '/auth/signupinsiders',
    MAGIC_LINK_PHONE: '/auth/link/phone',
    MORE_INFO: '/auth/more-info',
    MORE_INFO_LAST_SCREEN: '/auth/more-info/last-screen',
    MAGIC_LINK_EMAIL: '/auth/link/email',
    MOBILE_APP_CHECK_LOGIN_APPLE: '/auth/sso/mobile/apple',
    MOBILE_APP_CHECK_LOGIN_GOOGLE: '/auth/sso/mobile/google',
    MOBILE_APP_CHECK_LOGIN_ERROR: '/auth/sso/mobile/error',
};

export const API_ROUTES = {
    CHAT: {
        CREATE_OR_CONTINUE_SESSION: '/CreateOrContinueSession',
        HUMAN_STUDENT_TURN: '/HumanStudentTurn',
        FEEDBACK: '/MessageFeedback ',
        HISTORY: '/History',
        SESSION_SUMMARIES: '/SessionSummaries',
        DOWNLOAD_SESSION: '/DownloadSessionAsDocx',
    },
    AUTH: {
        REFRESHTOKEN: '/auth/refresh-token/',
        LOGIN: 'auth/login/',
        GOOGLE_CALENDAR_LOGIN: 'auth/login/google-calendar',
        OUTLOOK_CALENDAR_LOGIN: 'auth/login/outlook-calendar',
        REGISTER: 'auth/register',
        REDIRECT: 'auth/redirect',
        SEND_OTP: 'send-otp',
        VERIFY_OTP: 'verify-otp',
        REQUEST_MAGIC_LINK_PHONE: 'auth/link/phone',
        REQUEST_MAGIC_LINK_EMAIL: 'auth/link/email',
    },
    SETTINGS: {
        USER_PROFILE: '/userProfile',
        RESET_USER_PROFILE: '/reset',
        USER_FEEDBACK: '/userFeedback',
        PREFERRED_NAME: '/preferredName',
        REMOVE_CALENDAR_SYNC: '/removeCalendarSync',
    },
    USER: {
        UPDATE_USER_INFO: 'user/update/info',
        USER_APP_DATA: '/userAppData'
    },
    SPEECH: {
        GET_SPEECH_SERVICE_TOKEN: 'Speech/GenerateToken'
    },
    TASKS: {
        TASKS_LIST: '/tasks/list',
        UPDATE_TASK: '/tasks/update',
        CREATE_TASK: '/tasks/create',
        CLEAR_SCRATCHPAD: '/tasks/clear-scratchpad'
    },
    WORK_BLOCK: {
        CREATE_WORK_BLOCK: '/workblocks/create',
        UPDATE_WORK_BLOCK: '/workblocks/update',
    }
};


export const automaticBotNotificationEvents = {
    UX_SET_TASK_FIELD: '[UX_SET_TASK_FIELD]',
    UX_ENTER_FORM: '[UX_ENTER_FORM]',
    UX_ADD_TASK: '[UX_ADD_TASK]',
}

export enum ENewUserType {
    OPEN = 'Open',
    INTERNAL = 'Internal',
    EXTERNAL = 'External'
}
    export const botToolNames = {
    LIST_TASKS: '[LIST_TASKS]',
    ADD_TASK: '[END_ADD_TASK]',
    UPDATE_TASK: '[END_UPDATE_TASK]',
    DELETE_TASK: '[END_REMOVE_TASK]',
}

export enum EUserAgeSlice {
    MINOR = 1,
    TEENAGER,
    ADULT,
}

export const dataAttributes = {
    processingMessageType: 'data-processing-message-type',
    chatToastMessageDataType: 'data-chat-toast-message-data-type',
}

export const sendUserActivationConversionEvent = (data: any = {}) => {
    if (window.dataLayer) {
        window.dataLayer.push({
            event: 'webapp_user_activation',
            ...data
        });
    }
}

export const fullDayDurationInSeconds = 86400;

export enum EMagiLinkLoginPageViewType {
    REQUEST_MAGIC_LINK = 1,
    MAGIC_LINK_SENT,
    MAGIC_LINK_SENT_DESKTOP
}