import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigateWithSearchParams } from '../../../shared/utils/routingUtils';
import { APP_ROUTING_PATHS, ENewUserType, isSignUpLocalStorageKey, signUpTypeStorageKey } from '../../constants';
import { setItemInSessionOrLocalStorage } from '../../../shared/utils/getItemFromSessionOrLocalStorage';
import { ContentFrameWrapper } from '../../../shared/components/content-frame-wrapper/ContentFrameWrapper';
import { LottieAppLoader } from '../../../shared/components/lottie-loader/LottieLoader';
import LoginActionsButtons from '../login-actions-buttons/LoginActionsButtons';
import { ELoginPageViewType } from '../login/Login';
import { useAppDispatch } from '../../store';
import { resetLoginResState } from '../auth.store';
import signUpMascotImg from '../../../assets/images/mascot/main_1.png';
import { TermsOfService } from '../register/terms-of-service/TermsOfService';
import './SignUp.scss';

const SignUp = () => {
  const { t } = useTranslation();
  const [isPageLoading, setIsPageLoading] = useState(false);
  const navigate = useNavigateWithSearchParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    setItemInSessionOrLocalStorage(isSignUpLocalStorageKey, true, true);
  });

  const onBackToSignIn = () => {
    localStorage.removeItem(isSignUpLocalStorageKey);
    dispatch(resetLoginResState());
    navigate(APP_ROUTING_PATHS.SIGN_IN);
  }

  return (
    <div className='new-sign-up auth-screen shorten-onboarding-flow'>
      <ContentFrameWrapper className='new-sign-up-frame scroll-y-container-hidden-scroll-bar'>
        {isPageLoading ?
          <LottieAppLoader className='lottie-loader' />
          :
          <>
            <section>
              <div className='title-container'>
                <img src={signUpMascotImg} alt="mascot" className='mascot' />
                <h1 className='title'>
                  <span className='title --desktop'>{t("signUpTitleDesktop")}</span>
                  <span className='title --mobile'>{t("signUpTitleMobile")}</span>
                </h1>
              </div>
              <LoginActionsButtons userType={localStorage.getItem(signUpTypeStorageKey) as ENewUserType || ENewUserType.OPEN} viewType={ELoginPageViewType.NEW_USER_VIEW} isPageLoading={isPageLoading} setIsPageLoading={setIsPageLoading} />
            </section>
            <section>
              <p className='login-redirect-user-text'>{t("loginReturningUserAsk")}</p>
              <p className='login-redirect-user-text login-redirect-user-text--link' onClick={onBackToSignIn}>{t("loginSignIn")}</p>
              <TermsOfService shouldAddVerifiedAge={true} />
            </section>
          </>
        }
      </ContentFrameWrapper>
    </div>
  )
}

export default SignUp