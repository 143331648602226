import React from 'react'
import { ReactComponent as chatMaxIconComponent } from '../../../../assets/images/navbar-chat-max.svg';
import { ReactComponent as scratchpadIconComponent } from '../../../../assets/images/navbar-scratchpad.svg';
import { ReactComponent as planIconComponent } from '../../../../assets/images/navbar-plan.svg';
import { ReactComponent as planInActiveIconComponent } from '../../../../assets/images/navbar-inactive-plan.svg';
import { EAppStageContentType } from '../stage-container/stageContainer.interfaces';
import { NavButton } from './NavButton';
import { useSearchParams } from 'react-router-dom';
import { Avatar } from '../../../../shared/components/avatar/Avatar';
import { productTourDataAttrChat, productTourDataAttrPlan, productTourDataAttrScratchpad, productTourDataAttrTasks, productTourDataAttrUserAvatarDesk, settingsMenuParam, userInfoLocalStorageKey } from '../../../../app/constants';
import { IUser } from '../../../../app/auth/auth.interfaces';
import { getInitials } from '../../../../shared/utils/getInitials';
import { useLocalStorage } from '../../../../shared/utils/useLocalStorage';
import { useAppSelector } from '../../../../app/store';
import { ETasksGroupsType } from '../stage-container/stage-tasks/stageTasks.interface';
import { useTranslation } from 'react-i18next';
import { ReactComponent as tasksIconComponent } from '../../../../assets/images/navbar-tasks-active.svg';
import './Navbar.scss';
import AppButton from '../../../../shared/components/app-button/AppButton';

export const Navbar = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [userInfo,] = useLocalStorage<IUser | null>(userInfoLocalStorageKey, null);
  const { tasksOrderedByGroups } = useAppSelector(store => store.StageTasksReducer);
  const isScratchpadHasTasks = !!(tasksOrderedByGroups && tasksOrderedByGroups[ETasksGroupsType.SCRATCHPAD].length);
  const {t} = useTranslation();

  const onOpenSettingsMenu = () => {
    if (!searchParams.get(settingsMenuParam)) {
      setSearchParams(prev => {
        prev.append(settingsMenuParam, 'open');
        return prev;
      });
    }
  }

  return (
    <div className='navbar-container'>
      <div className="action-buttons">
        <NavButton className='chat-btn' type={EAppStageContentType.CHAT} title={t("navbarChatTitle")}  SvgComponent={chatMaxIconComponent} productTourDataAttr={productTourDataAttrChat}/>
        <NavButton className={`scratchpad-btn ${isScratchpadHasTasks ? 'scratchpad-btn--full' : ''}`} type={EAppStageContentType.SCRATCHPAD} title={t("navbarScratchPadTitle")} SvgComponent={scratchpadIconComponent} productTourDataAttr={productTourDataAttrScratchpad}/>
        <NavButton className='tasks-btn' type={EAppStageContentType.TASKS} title={t("navbarTasksTitle")} SvgComponent={tasksIconComponent} productTourDataAttr={productTourDataAttrTasks}/>
        <NavButton className='plan-btn' type={EAppStageContentType.PLANNER} title={t("navbarPlanTitle")} SvgComponent={planIconComponent} InActiveSvgComponent={planInActiveIconComponent} productTourDataAttr={productTourDataAttrPlan}/>
      </div>
      <AppButton id="navbar-user-avatar" className="user-avatar" data-product-tour={productTourDataAttrUserAvatarDesk} onClick={onOpenSettingsMenu}>
        <Avatar
          className='navbar-avatar'
          defaultAvatarClassName='default-header-avatar'
          size={40}
          avatarImg={userInfo?.profileImage || ""}
          defaultContent={getInitials(userInfo?.name || userInfo?.email || "")}
        />
      </AppButton>
    </div>
  )
}
