import { useEffect, useState } from "react";
import { useAppSelector } from "../../app/store";
import { useApiData } from "./useApiData";
import { removeDataAttributeOnBody, setDataAttributeOnBody } from "../utils/utils";
import { dataAttributes } from "../../app/constants";
import { setQueueMessageData } from "../../features/chat-wrapper/chat.store";

export type TChatQueueMessageType = 'automatic' | 'manual';

export interface IUseChatMessageQueuePayload {
    type: TChatQueueMessageType;
    botRequestJson: string;
    localUserChatMessage?: string;
}

export const useChatMessageQueue = (): [TChatQueueMessageType | null, IUseChatMessageQueuePayload | null] => {
    const { botResponse, queueMessageData, sessionId } = useAppSelector(store => store.chatReducer);
    const { shouldDisplayProductTour } = useAppSelector(store => store.sharedStoreReducer);
    const [isPending, setIsPending] = useState<boolean>(true);
    const [queue, setQueue] = useState<IUseChatMessageQueuePayload[]>([]);
    const [processingType, setProcessingType] = useState<TChatQueueMessageType | null>(null);
    const [nextMessage, setNextMessage] = useState<IUseChatMessageQueuePayload | null>(null);


    useEffect(() => {
        if (queueMessageData) {
            if (shouldDisplayProductTour) {
                setQueueMessageData(null);
                return;
            }
            if (isPending || queue.length > 0) {
                setQueue((currentQueue) => {
                    return [...currentQueue, queueMessageData];
                });
            } else {
                setDataAttributeOnBody(dataAttributes.processingMessageType, queueMessageData.type);
                setProcessingType(queueMessageData.type);
                setNextMessage(queueMessageData);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queueMessageData]);

    const takeOutFirstMessage = () => {
        if (queue.length) {
            const firstMessage = queue[0];
            setDataAttributeOnBody(dataAttributes.processingMessageType, firstMessage.type);
            setProcessingType(firstMessage.type);
            setNextMessage(firstMessage);
            setQueue((currentQueue) => {
                return currentQueue.slice(1);
            });
        } else {
            resetQueueState();
        }
    }

    useApiData(botResponse, {
        onFulfilled() {
            takeOutFirstMessage();
            setIsPending(false);
        },
        onPending() {
            setIsPending(true);
        },
        onRejected() {
            // TODO: implement a more robust solution for handling errors when there are messages in the queue
            // for now, just reset the queue state
            // in the era pre automated messages, if there was an error in the bot response api, we removed the last user message from the chat 
            // and placed it back inside the input box and the user could re-send it again.
            // this is still the case, but we also have automated messages which do not have a retry logic implemented in the ui yet.
            resetQueueState();
        }
    });

    // when app loads we need to wait for the session to be retrieved before processing any messages in the queue
    // this code makes sure the queue does not process any messages until the session is retrieved
    useApiData(sessionId, {
        onFulfilled() {resetQueueState();},
        onPending() {setIsPending(true);},
        onRejected() {resetQueueState();}
    });

    const resetQueueState = () => {
        if (processingType) {
            removeDataAttributeOnBody(dataAttributes.processingMessageType);
            setProcessingType(null);
        }
        if (nextMessage)
            setNextMessage(null);
        if (isPending) 
            setIsPending(false);
        if (queue.length > 0) {
            setQueue([]);
        }
    }

    return [processingType, nextMessage];

};