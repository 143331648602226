import './App.scss';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Suspense, useEffect, useState } from 'react';
import { I18Provider } from './shared/i18/i18nProvider';
import { useAppSelector } from './app/store';
import { ConfigProvider } from 'antd';
import i18n from './shared/i18/i18n';
import { defaultAntdLocale, locales } from './shared/i18/antd_locales';
import { Page404 } from './shared/components/page404/Page404';
import { ChatWrapper } from './features/chat-wrapper/ChatWrapper';
import { APP_ROUTING_PATHS, ENewUserType } from './app/constants';
import { ELoginPageViewType, Login } from './app/auth/login/Login';
import { AuthLayout } from './app/auth/auth-layout/AuthLayout';
import { Auth } from './app/auth/Auth';
import { PrivateRoute } from './app/guards/PrivateRoute';
import { Main } from './app/main/Main';
import { CheckLogin } from './app/auth/check-login/CheckLogin';
import { Register } from './app/auth/register/Register';
import { ProtectedRegisterRoute } from './app/guards/ProtectedRegisterRoute';
import AppEventListenerHandler from './shared/components/appEventListenerHandler/AppEventListenerHandler';
import { StayLoggedIn } from './app/auth/stay-logged-in/StayLoggedIn';
import { ProtectedStayLoggedInRoute } from './app/guards/ProtectedStayLoggedInRoute';
import { ProtectedLoginRoute } from './app/guards/ProtectedLoginRoute';
import { Redirect } from './app/guards/Redirect';
import { TabSwitchingEvenHandler } from './shared/components/tabSwitchingEvenHandler/TabSwitchingEvenHandler';
import AppModals from './shared/components/app-modals/AppModals';
import { SessionSourceExtraction } from './features/session-source-extraction/SessionSourceExtraction';
import { AgeGate } from './app/auth/register/age-gate/AgeGate';
import { WaitList } from './app/auth/register/wait-list/WaitList';
import { WelcomeCarousel } from './app/auth/welcome-carousel/WelcomeCarousel';
import { LoginErrorPage } from './app/auth/login/login-pages/LoginErrorPage';
import { formatUrlWithParams, saveImportantParamsInLocalStorageOnAppInit } from './shared/utils/routingUtils';
import { LoginWithEmailPage } from './app/auth/login/login-pages/LoginWithEmailPage';
import EnterPhoneAuthPage from './app/auth/phone-verification-auth-flow/enter-phone-auth-page/EnterPhoneAuthPage';
import PhoneNumberVerificationAuthPage from './app/auth/phone-verification-auth-flow/phone-verification-auth-page/PhoneNumberVerificationAuthPage';
import LoginWithPhone from './app/auth/login/login-pages/login-with-phone/LoginWithPhone';
import { MobileAppCheckLogin } from './app/auth/mobile-app-check-login/MobileAppCheckLogin';
import { ELoginType, EOperatingSystem } from './app/auth/auth.interfaces';

function App() {
    const { languageCode } = useAppSelector((store) => store.sharedStoreReducer);
    const [direction, setDirection] = useState<'ltr' | 'rtl' | null>(null);

    useEffect(() => {
        setDirection(i18n.dir(languageCode));
        saveImportantParamsInLocalStorageOnAppInit();
    }, [languageCode]);

    return (
        <div className="App">
            <Suspense fallback="loading">
                <I18Provider>
                    <AppEventListenerHandler />
                    <TabSwitchingEvenHandler />
                    {direction && (
                        <ConfigProvider direction={direction} locale={locales[languageCode] || defaultAntdLocale}>
                            <Auth>
                                <Routes>
                                    <Route path={APP_ROUTING_PATHS.AUTHLAYOUT} element={<AuthLayout />}>
                                        {/* remove SIGN_UP_OPEN  in future releases */}
                                        <Route path={APP_ROUTING_PATHS.SIGN_UP_OPEN} element={<ProtectedLoginRoute><Login viewType={ELoginPageViewType.NEW_USER_VIEW} userType={ENewUserType.OPEN} /></ProtectedLoginRoute>} />
                                        <Route path={APP_ROUTING_PATHS.SIGN_IN} element={<ProtectedLoginRoute><Login viewType={ELoginPageViewType.RETURNING_USER_VIEW} userType={ENewUserType.OPEN} /></ProtectedLoginRoute>} />
                                        <Route path={APP_ROUTING_PATHS.SIGN_UP_INSIDERS} element={<ProtectedLoginRoute><Login viewType={ELoginPageViewType.NEW_USER_VIEW} userType={ENewUserType.EXTERNAL} /></ProtectedLoginRoute>} />
                                        <Route path={APP_ROUTING_PATHS.SIGN_UP} element={<ProtectedLoginRoute><Login viewType={ELoginPageViewType.NEW_USER_VIEW} userType={ENewUserType.OPEN} /></ProtectedLoginRoute>} />
                                        <Route path={APP_ROUTING_PATHS.MAGIC_LINK_PHONE} element={<ProtectedLoginRoute><LoginWithPhone /></ProtectedLoginRoute>} />
                                        <Route path={APP_ROUTING_PATHS.MAGIC_LINK_EMAIL} element={<ProtectedLoginRoute><LoginWithEmailPage /></ProtectedLoginRoute>} />
                                        <Route path={APP_ROUTING_PATHS.MORE_INFO} element={<WelcomeCarousel />} />
                                        <Route path={APP_ROUTING_PATHS.MORE_INFO_LAST_SCREEN} element={<WelcomeCarousel isTheLastElementDisplayOnTheScreenInitialValue={true} />} />
                                        <Route path={APP_ROUTING_PATHS.SSO} element={<CheckLogin />} />
                                        <Route path={APP_ROUTING_PATHS.SSO_MAGIC_LINK} element={<CheckLogin isLoginFromMagicLink={true} />} />
                                        <Route path={APP_ROUTING_PATHS.SSO_MAGIC_LINK_INSIDERS} element={<CheckLogin isLoginFromMagicLink={true} isInsider={true} />} />
                                        <Route path={APP_ROUTING_PATHS.SSO_FAILED} element={<LoginErrorPage />} />
                                        <Route path={APP_ROUTING_PATHS.STAY_LOGGED_IN} element={<ProtectedStayLoggedInRoute><StayLoggedIn /></ProtectedStayLoggedInRoute>} />
                                        <Route path={APP_ROUTING_PATHS.AGE_VERIFICATION} element={<AgeGate />} />
                                        <Route path={APP_ROUTING_PATHS.WAIT_LIST} element={<ProtectedRegisterRoute><WaitList /></ProtectedRegisterRoute>}/>
                                        <Route path={APP_ROUTING_PATHS.REGISTER} element={<ProtectedRegisterRoute><Register /></ProtectedRegisterRoute>} />
                                        <Route path={APP_ROUTING_PATHS.ADD_PHONE_REGISTER} element={<ProtectedRegisterRoute><EnterPhoneAuthPage /></ProtectedRegisterRoute>} />
                                        <Route path={APP_ROUTING_PATHS.PHONE_VERIFICATION} element={<ProtectedRegisterRoute><PhoneNumberVerificationAuthPage /></ProtectedRegisterRoute>} />
                                        <Route path={APP_ROUTING_PATHS.MOBILE_APP_CHECK_LOGIN_APPLE} element={<MobileAppCheckLogin loginType={ELoginType.APPLE} operatingSystem={EOperatingSystem.IOS}/>} />
                                        <Route path={APP_ROUTING_PATHS.MOBILE_APP_CHECK_LOGIN_GOOGLE} element={<MobileAppCheckLogin loginType={ELoginType.GOOGLE} operatingSystem={EOperatingSystem.ANDROID}/>} />
                                        <Route path={APP_ROUTING_PATHS.MOBILE_APP_CHECK_LOGIN_ERROR} element={<LoginErrorPage />} />
                                        <Route path={APP_ROUTING_PATHS.AUTHLAYOUT} element={<Navigate to={APP_ROUTING_PATHS.SIGN_IN} replace />} />
                                    </Route>
                                    <Route path={APP_ROUTING_PATHS.HOME} element={<PrivateRoute><><AppModals /><Main /></></PrivateRoute>}>
                                        <Route path={APP_ROUTING_PATHS.CHAT} element={<ChatWrapper />} />
                                    </Route>
                                    <Route path={APP_ROUTING_PATHS.SESSION_SOURCE_TYPE} element={<SessionSourceExtraction />} />
                                    <Route path={APP_ROUTING_PATHS.REDIRECT} element={<Redirect />} />
                                    <Route path={APP_ROUTING_PATHS.PAGE_404} element={<Page404 />} />
                                    <Route path="*" element={<Navigate to={APP_ROUTING_PATHS.PAGE_404} replace />} />
                                    <Route path="/" element={<Navigate to={formatUrlWithParams(APP_ROUTING_PATHS.REDIRECT)} replace />} />
                                </Routes>
                            </Auth>
                        </ConfigProvider>
                    )}
                </I18Provider>
            </Suspense>
            <div id='app-overlay-popover-container' />
        </div>
    );
}

export default App;
