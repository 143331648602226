import React, { useCallback, useEffect, useRef, useState } from 'react'
import { AppSelect } from '../../app-select/AppSelect'
import { Controller, useForm } from 'react-hook-form';
import { AddPhoneRegistrationForm, IUser } from '../../../../app/auth/auth.interfaces';
import { useAppDispatch, useAppSelector } from '../../../../app/store';
import { disallowedCountries, isDesktopView, supportedCountries, supportedCountriesInternal } from '../../../utils/utils';
import { userInfoLocalStorageKey, userPhoneCountryTwoLetterCodeLocalStorageKey, userPhoneNumberLocalStorageKey } from '../../../../app/constants';
import { useLocalStorage } from '../../../utils/useLocalStorage';
import { useTranslation } from 'react-i18next';
import { sendOtpReqAction, updatePhoneNumberReqAction } from '../../../../app/auth/auth.store';
import { useApiData } from '../../../hooks/useApiData';
import en from 'react-phone-number-input/locale/en.json';
import phoneMascotImg from '../../../../assets/images/mascot/phone-mascot.png';
import PhoneInput, { getCountries, getCountryCallingCode, isValidPhoneNumber } from 'react-phone-number-input/input';
import { ContactUs } from '../../../../app/auth/register/contact-us/ContactUs';
import { parsePhoneNumber } from 'react-phone-number-input';
import './EnterPhoneAndGetOtpForm.scss';
import { ApplicationInsightsApi } from '../../../../application-insights';

interface IEnterPhoneAndGetOtpFormProps {
  onFulfilledSendOtp: () => void;
  title: string;
  subTitle: string;
  className?: string;
  defaultPhoneNumber?: string;
  isRegistrationFlow?: boolean;
  trackEventName: string;
}

const EnterPhoneAndGetOtpForm = ({ onFulfilledSendOtp, className = '', title, subTitle, trackEventName, defaultPhoneNumber = '', isRegistrationFlow=false }: IEnterPhoneAndGetOtpFormProps) => {
  const { control, handleSubmit, setFocus, formState, reset, setValue } = useForm<AddPhoneRegistrationForm>({
    defaultValues: new AddPhoneRegistrationForm()
  });
  const { sendOtpRes, registrationRes } = useAppSelector(store => store.authReducer);
  const { userAppData } = useAppSelector(store => store.userReducer);
  const [userInfo,] = useLocalStorage<IUser | null>(userInfoLocalStorageKey, null);
  const [, setPhoneNumber] = useLocalStorage<string | null>(userPhoneNumberLocalStorageKey, null);
  const isInternalUser = userAppData?.data?.user?.isInternalUser || registrationRes?.data?.isInternalUser || userInfo?.isInternalUser || false;
  const [, setPhoneCountryTwoLetterCode] = useLocalStorage<string | null>(userPhoneCountryTwoLetterCodeLocalStorageKey, null);
  const supportedCountriesList = isInternalUser ? supportedCountriesInternal : supportedCountries;
  const [shouldDisplayError, setShouldDisplayError] = useState(false);
  const resetPhoneTimer = useRef<NodeJS.Timeout | null>(null);
  const [country, setCountry] = useState<string>(initCountry());
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    // set auto focus on the userPhone input
    if (isDesktopView()) setFocus(`userPhone`);
    if (defaultPhoneNumber) reset({ 'userPhone': defaultPhoneNumber });

    return () => {
      // clear Timeout when the component destroyed
      if (resetPhoneTimer.current) clearTimeout(resetPhoneTimer.current);
    }
  }, [defaultPhoneNumber, reset, setFocus])

  function initCountry() {
    if (defaultPhoneNumber) {
      const phoneNumberObj = parsePhoneNumber(defaultPhoneNumber);
      if (phoneNumberObj && phoneNumberObj.country) return phoneNumberObj.country;
    } return 'US';
  };

  // concat the user phone number to a string and send to the server.
  const onSubmit = useCallback((formData: AddPhoneRegistrationForm) => {
    setPhoneCountryTwoLetterCode(country);
    const phoneNumber = `${formData.userPhone.replaceAll('-', '').replaceAll('(', '').replaceAll(')', '').trim()}`;
    setPhoneNumber(phoneNumber);
    dispatch(sendOtpReqAction({ phoneNumber: `${phoneNumber}` }))
      .unwrap()
      .then((sendOtpResData) => {
        if (sendOtpResData) {
          onFulfilledSendOtp();
          ApplicationInsightsApi.trackEvent(trackEventName, {request: { phoneNumber: `${phoneNumber}` }, response: sendOtpResData});
        }
      })
  }, [setPhoneCountryTwoLetterCode, country, setPhoneNumber, dispatch, onFulfilledSendOtp, trackEventName]);

  // reset and set focus the phone text-box input on Rejected after 500ms
  const resetPhoneInput = () => {
    if (resetPhoneTimer.current) clearTimeout(resetPhoneTimer.current);
    resetPhoneTimer.current = setTimeout(() => {
      setFocus('userPhone');
      reset({ 'userPhone': '' });
    }, 500);
  }

  useApiData(sendOtpRes, {
    //  reset phone input when registration API rejected
    onRejected(e) {
      setShouldDisplayError(true);
      resetPhoneInput();
      ApplicationInsightsApi.trackException(`${trackEventName} sendOtpRequest failed with error: ${e}`);
    },
  });

  const handleUnsupportedCountry = useCallback(() => {
    if (supportedCountriesList.includes(country)) return;
    dispatch(updatePhoneNumberReqAction({ phoneCountryTwoLetterCode: country }));
  }, [country, dispatch, supportedCountriesList]);

  useEffect(() => {
    if(isRegistrationFlow) handleUnsupportedCountry();
  }, [handleUnsupportedCountry, isRegistrationFlow]);

  const handleChangeCountry = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setCountry(event.target.value);
    setValue('userPhone', "", { shouldValidate: true });
  }

  const getCountriesSorted = () => {
    const allowedCountries = getCountries().filter(c => !disallowedCountries.includes(c));
    const supportedCountries = allowedCountries.filter(c => supportedCountriesList.includes(c)).sort((a, b) => en[a].localeCompare(en[b]));
    const allowedCountriesWithoutSupported = allowedCountries.filter(c => !supportedCountriesList.includes(c)).sort((a, b) => en[a].localeCompare(en[b]));
    return supportedCountries.concat(allowedCountriesWithoutSupported);
  }

  return (
    <form className={`scroll-y-container-hidden-scroll-bar phone-verification-flow enter-phone-number-get-otp ${className}`} id="update-phone-form" onSubmit={handleSubmit(onSubmit)} data-testid="update-phone-form">
      <div className='update-phone-form-content'>
        <div className='title-container'>
          <img src={phoneMascotImg} alt="mascot" className='mascot' />
          <h1 className='title'>{title}</h1>
        </div>
        <p className='add-phone-registration-permission-text add-phone-registration-permission-text--main'>{subTitle}</p>
        <div className="registration-input-container">
          <AppSelect
            className='phone-registration-container'
            value={country}
            onChange={handleChangeCountry}
            options={getCountriesSorted().map(c => ({
              value: c,
              label: <>+{getCountryCallingCode(c)}<div className='img-wrapper'><img src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${c}.svg`} alt={`${c}-flag`} className='flag-img' />{en[c]}</div></>, 
              selectedLabel: <><img src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${c}.svg`} alt={`${c}-flag`} className='flag-img' />+{getCountryCallingCode(c)}</>,
              labelStringForTrackEvent: `+${getCountryCallingCode(c)} ${en[c]}`,
              className: `country-initials-${c.toLocaleLowerCase()}`
            }))}
          />
          {(supportedCountriesList.includes(country)) && <>
            <Controller
              name='userPhone'
              control={control}
              rules={{ validate: (value) => isValidPhoneNumber(`${value}`) }}
              render={({ field: { onChange, value } }) => (
                <PhoneInput
                  id={`user-phone-input`}
                  data-testid={`user-phone-input`}
                  className={`register-input ${formState.isValid && 'valid-mode'} ${shouldDisplayError && 'red-border-error'}`}
                  placeholder='XXX-XXX-XXXX'
                  value={value}
                  onChange={onChange}
                  maxLength={15 - (getCountryCallingCode(country as any)?.length || 0)}
                  country={country as any}
                />
              )}
            />
            {/* if there is error message from the server display it, else display static message */}
            {shouldDisplayError && <small className='error' data-testid="update-phone-error">{t("enterValidPhoneNumberError")}</small>}
          </>}
        </div>
        <button className='auth-next-btn submit' form="update-phone-form" id="update-phone-form-submit" data-testid="update-phone-form-submit" type='submit' disabled={!formState.isValid || formState.isSubmitted}>{t('addPhoneRegistrationScreenSendBtnText')}</button>
        {!supportedCountriesList.includes(country) && <div>
          <p className='add-phone-registration-permission-text'>{t("addPhoneRegistrationScreenPermissionText3")}</p>
          <p className='add-phone-registration-permission-text'>{t("addPhoneRegistrationScreenPermissionText4")}</p>
          <p className='add-phone-registration-permission-text'>{userInfo?.email}</p>
        </div>}
      </div>
      {(supportedCountriesList.includes(country)) ? <p className='agreement-paragraph' data-testid="update-phone-agreement">
            {t("phoneRegistrationScreenParagraph2NewFlow")}
          </p> : <ContactUs />}
    </form>
  )
}

export default EnterPhoneAndGetOtpForm