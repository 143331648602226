import { MutableRefObject } from "react";
import { IUser } from "../../../../app/auth/auth.interfaces";
import { discordLink, discordLinkForOpenUsers, discordToastId, ENewUserType, shouldShowDiscordToastInCurrentSessionKey, turnsAmountToDisplayDiscordToast, turnsCounterStorageKey, userInfoLocalStorageKey } from "../../../../app/constants";
import { getItemFromSessionOrLocalStorage, setItemInSessionOrLocalStorage } from "../../../utils/getItemFromSessionOrLocalStorage";
import { EClientFlags } from "../../content-frame-wrapper/settings-menu/SettingsMenu.interfaces";
import { openAppToastPopUp } from "./handleAppToasts.util";
import { IChatMessage } from "../../../../features/chat-wrapper/chat.interfaces";

// handle Discord toast
const displayDiscordPopUpToast = (turnsCounterRef: MutableRefObject<number>) => {
  const signUpType = getItemFromSessionOrLocalStorage<IUser>(userInfoLocalStorageKey)?.signUpType;

  openAppToastPopUp({
    id: discordToastId,
    body: "discordPopUpToastBody",
    CTA_Text: "joinOurDiscordLinkPart1",
    CTA_Href: `${signUpType === ENewUserType.OPEN ? discordLinkForOpenUsers : discordLink}`,
    dontShowAgainFlag: EClientFlags.DONT_SHOW_AGAIN_DISCORD_TOAST,
    dontShowAgainBtnText: "discordPopUpToastDontShowAgainText",
    onClose: () => {
      setItemInSessionOrLocalStorage(shouldShowDiscordToastInCurrentSessionKey, false, true);
      turnsCounterRef.current = 0;
      localStorage.removeItem(turnsCounterStorageKey);
    }
  })
}

export const handleShowDiscordToast = (newMessages: IChatMessage[], turnsCounterRef: MutableRefObject<number>) => {
  // if should not display the toast in the current session -> return
  const shouldShowToastCurrentSessionLocal = getItemFromSessionOrLocalStorage<boolean>(shouldShowDiscordToastInCurrentSessionKey);
  if (!shouldShowToastCurrentSessionLocal && shouldShowToastCurrentSessionLocal !== null) return;

  // count 10 turns for showing the discord toast
  if (turnsCounterRef.current < turnsAmountToDisplayDiscordToast) {
    let shouldIncrementTurnCounter = false;

    // if there is at least one bot message with content, increase the turnsCounterRef by 1
    for (let i = 0; i < newMessages.length; i++) {
      if (!!newMessages[i].msg && newMessages[i].msg.length > 0) {
        shouldIncrementTurnCounter = true;
        break;
      }
    }

    if (shouldIncrementTurnCounter) {
      setItemInSessionOrLocalStorage(turnsCounterStorageKey, turnsCounterRef.current + 1, true);
      turnsCounterRef.current = turnsCounterRef.current + 1;
    }
  }
  if (turnsCounterRef.current >= turnsAmountToDisplayDiscordToast) {
    displayDiscordPopUpToast(turnsCounterRef);
  }
}

// with new session reset turnsCounterRef, and shouldDisplayDiscordToastCurrentSession
export const resetDiscordToastVariablesWithNewSession = (turnsCounterRef: MutableRefObject<number>) => {
  turnsCounterRef.current = 0;
  setItemInSessionOrLocalStorage(turnsCounterStorageKey, 0, true);
  setItemInSessionOrLocalStorage(shouldShowDiscordToastInCurrentSessionKey, true, true);
}